import React, { useEffect, Suspense } from 'react';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import packageJson from "./package.alias.json";
import * as Bootstrap from 'bootstrap';
import * as Popper from 'popper.js';
import appStyles from './App.css';
import { Login } from './login/Login';
import { VideoPlayer } from './player/VideoPlayer';
import { LivePlayer } from './player/live/LivePlayer';
import { VideosHome } from './home/VideosHome';
import { TagsHome } from './home/TagsHome';
import { GroupsHome } from './home/GroupsHome';
import { ProfileHome } from './profile/ProfileHome';
import { NewVideo } from './uploader/NewVideo';
import { InjectAxiosInterceptors } from './utils/InjectAxiosInterceptors';
import { SignUp } from './signup/SignUp';
import { Page404 } from './utils/others/Page404';
import { RequestPasswordRecovery } from './recovery/RequestPasswordRecovery';
import { VerificateAccount } from './signup/VerificateAccount';
import { VerificatePasswordRecovery } from './recovery/VerificatePasswordRecovery';
import { AutoShare } from './share/AutoShare';
import { InjectUserPreferences } from './utils/preferences/InjectUserPreferences';
import { GlobalLoader } from './utils/comms/http';
import { GeneralContextProvider } from './contexts/general-context';
import { InjectTranslationConfig } from './utils/translations/InjectTranslationConfig';
import { Skeleton } from './utils/skeleton/Skeleton';
import { MarkdownRender } from './others/MarkdownRender';
import { config } from './config';
import changelogContent from './texts/CHANGELOG.link.md';
import { CustomToastProvider } from './utils/toasts/CustomToastProvider';
import { useLocation } from 'react-router-dom';
import { ModalStatus } from './modals/ModalStatus';
import SubscriptionSuccess from './pages/SubscriptionSuccess';

function App() {

  let location = useLocation();
  
  const isLoginPage = location.hash.includes('login') || 
                      location.hash.includes('sign-up') || 
                      location.hash.includes('recovery') || 
                      location.hash.includes('verificate') || 
                      location.hash.includes('invitation') ;

  const callBootstrap = () => {
    if(config.IS_BETA) console.log(`Is bootstrap working? ${Bootstrap !== undefined}`);
    if(config.IS_BETA) console.log(`Is popper.js working? ${Popper !== undefined}`);
  }

  const verifyLocalCSS = () => {
    if(config.IS_BETA) console.log(`Are local files ok? ${appStyles !== undefined}`);
  }

  const initialSetup = () => {
    document.title = 'ViTAG — Video Tagging System';
  }

  callBootstrap();
  verifyLocalCSS();
  initialSetup();

  return (
    <Suspense fallback={<Skeleton/>}>
      { config.IS_BETA && <a href={ isLoginPage ? null : "/#/changelog"} className="beta-indicator d-flex flex-column">
        <span>BETA</span>
        <span style={{fontSize:'16px',color:'black'}}>{packageJson.version}</span>
      </a> }
      <CustomToastProvider>
        <Router>
          <InjectAxiosInterceptors/>
          <InjectTranslationConfig/>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/sign-up" component={SignUp} />
            <Route exact path="/verificate/:token" component={VerificateAccount} />
            <Route exact path="/invitation/:token" component={SignUp} />
            <Route exact path="/recovery" component={RequestPasswordRecovery} />
            <Route exact path="/recovery/:token" component={VerificatePasswordRecovery} />
            <Route exact path="/loading" component={Skeleton} />
            <Route exact path="/changelog">
              <MarkdownRender mdFileReference={changelogContent}>
                <ModalStatus/>
              </MarkdownRender>
            </Route>
            <GeneralContextProvider>
              <Route exact path="/upload" component={NewVideo} />
              <Route exact path="/video/:hashId" component={VideoPlayer} />
              <Route exact path="/live/:hashId" component={LivePlayer} />
              <Route exact path="/share-video/:hashId" component={AutoShare} />
              <Route exact path="/tags" component={TagsHome} />
              <Route exact path="/groups" component={GroupsHome} />
              <Route exact path="/me" component={ProfileHome} />
              <Route exact path="/home" component={VideosHome} />
              <Route exact path="/videos" component={VideosHome} />
              <Route exact path="/success-subscription" component={SubscriptionSuccess} />
              <Route exact path="/" component={VideosHome} />
              <InjectUserPreferences/>
            </GeneralContextProvider>
            <Route component={Page404} />
          </Switch>
        </Router>
      </CustomToastProvider>
      <GlobalLoader/>
    </Suspense>
  );
}

export default App;
