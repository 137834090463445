import { useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { setupInterceptors } from "./comms/http"
import { config } from '../config';
import useExternalCheckoutModal from "../hooks/UseExternalCheckoutModal";

function InjectAxiosInterceptors () {
  const history = useHistory();
  const location = useLocation();
  const { openCheckoutModal, modalExternalCheckoutComponent } = useExternalCheckoutModal();

  useEffect(() => {
    if(config.IS_BETA) console.log('This effect (setup interceptors) is called once');
    setupInterceptors(history, location, openCheckoutModal);
  }, [history]);

  // not rendering anything
  return <>
    {modalExternalCheckoutComponent}
  </>;
}

export { InjectAxiosInterceptors };
