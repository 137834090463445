import React, { useEffect, useState } from "react";
import { AppNavbar } from "../general/AppNavbar";
import { ProgressBar } from "react-bootstrap";

function SubscriptionSuccess() {

    const secondsToRedirect = 20;

    const [timer, setTimer] = useState(100);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(prev => {
                if (prev <= 0) {
                    clearInterval(interval);
                    window.location.href = '/';
                    return 0;
                }
                return prev - (100 / secondsToRedirect); // Reducir progresivamente en 6 segundos
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const goBackToHome = () => {
        window.location.href = '/';
    }

    return (
        <div>
            <AppNavbar showContextSelector={false} showProfile={false}/>
            <div className="container pt-5">
                <div className="d-flex flex-column align-items-center justify-content-center gap-3 py-5">
                    <h2>¡Gracias por suscribirte a ViTAG PRO!</h2>
                    <p>Ahora puedes disfrutar de todos los beneficios de tener una cuenta profesional.</p>
                    <div className="card p-4 w-50">
                        <h4 className="text-center">🎉 Beneficios de ViTAG PRO 🎉</h4>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">✅ Cantidad ilimitada de videos</li>
                            <li className="list-group-item">🤖 Etiquetado de videos con IA</li>
                            <li className="list-group-item">📚 Acceso a contenidos exclusivos de ViTAG</li>
                            <li className="list-group-item">💬 Acceso a canales de comunicación</li>
                        </ul>
                    </div>
                    <button onClick={goBackToHome} className="btn btn-vitag mt-3">Ir a la página de inicio</button>
                    <div className="w-50">
                        <ProgressBar animated now={timer} variant="danger" style={{ height: '10px' }} />
                        <p className="text-center mt-2">Se redirigirá automáticamente al inicio en {secondsToRedirect} segundos...</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SubscriptionSuccess;
