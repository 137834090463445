import axios from 'axios';

function subscriptionsByOrganization(organizationId) {
    return axios.get(`/vtg-subscriptions/by-organization/${organizationId}`, {params:{onlyMostRecent:true}});
}

function getSubsInfo() {
    return axios.get('/user-account/my-subscriptions');
}

function createSubscription(isUpgrade=false, source="DEFAULT", ownerId, planId = -1, pay = false) {
    if (ownerId == undefined) {
        console.error('Owner is required');
        return;
    }
    const params = new URLSearchParams();
    params.append('isUpgrade', isUpgrade);
    params.append('source', source);
    params.append('outer', false);
    params.append('owner', ownerId);
    params.append('planId', planId);
    params.append('pay', pay);
    return axios.post(`/user-account/new-subscription?${params.toString()}`);
}


function listAllPlans() {
    return axios.get(`/user-account/all-plans`);
}

function cancelStripeSubscription(subscriptionId, userId) {
    const params = new URLSearchParams();
    params.append('subscriptionId', subscriptionId);
    params.append('userId', userId);

    return axios.post(`/checkout/cancel-subscription?${params.toString()}`);
}

function downloadInvoice(subscriptionId) {
    const params = new URLSearchParams();
    params.append('subscriptionId', subscriptionId);
    return axios.get(`/checkout/download-invoice?${params.toString()}`);
}

function listProductsFromStripe() {
    return axios.get(`/vtg-subscriptions/products`);
}

function createCheckoutSession(productPriceId) {
    return axios.post(`/vtg-subscriptions/create-checkout`, { productPriceId });
}

export {
    subscriptionsByOrganization, getSubsInfo, 
    createSubscription, listAllPlans, cancelStripeSubscription, downloadInvoice,
    listProductsFromStripe, createCheckoutSession
};
