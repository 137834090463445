import React, { useContext, useState } from 'react';
import { GeneralContext } from '../../contexts/general-context';
import { ModalExternalCheckout } from '../../modals/ModalExternalCheckout';
import { useTranslation } from 'react-i18next';

function VitagProAd() {

    const { isProUser } = useContext(GeneralContext);
    const [showModal, setShowModal] = useState(false);
    const { t } = useTranslation();

    const adPanelStyle = {
        backgroundColor: '#ededed',
    }

    return ! isProUser && <>
        <div className="row justify-content-center px-5">
            <div style={adPanelStyle} className="col-12 col-sm-8 col-lg-6 extra-rounded d-flex justify-content-between gap-4 px-4 py-3">
                <div className="d-flex flex-column">
                    <h5 className='fw-bold'>Aún no tienes una cuenta PRO</h5>
                    <p className='small mb-0'>Para ver las opciones de suscripción y saber más sobre las funcionalidades de ViTAG PRO, puedes dar clic en este botón</p>
                </div>
                <div className='flex-grow-1 align-self-center'>
                    <button onClick={() => setShowModal(true)} className="btn btn-vitag btn-sm text-nowrap">
                        Ver planes
                    </button>
                </div>
            </div>
        </div>
        <ModalExternalCheckout showModal={showModal} onHide={() => setShowModal(false)}></ModalExternalCheckout>
    </>
}

export { VitagProAd };