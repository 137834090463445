import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { createCheckoutSession, listProductsFromStripe } from "../services/VitagSubscriptionsService";
import SkeletonSubscriptionCard from "../general/SkeletonSubscriptionCard";

function ModalExternalCheckout({showModal, onHide}) {

    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    const initModal = () => {
        setLoading(true);
        listProductsFromStripe()
            .then(response => {
                setProducts(response.data || []);
                console.log("Products from stripe", response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error getting products from stripe", error);
            });
    }

    const tryToCreateCheckoutSession = (productPriceId) => {
        createCheckoutSession(productPriceId)
            .then(response => {
                console.log("Checkout session created", response.data);
                window.location.href = response.data.checkoutUrl;
            })
            .catch(error => {
                console.error("Error creating checkout session", error);
            });
    }

    return <>
        <Modal show={showModal} onShow={initModal} onHide={onHide} fullscreen='true' size='xl' style={{ zIndex: 1070 }}>
            <div className="px-3 pb-4">
                <div className="w-100 d-flex align-items-top justify-content-end py-3">
                    <button type="button" className="btn p-0 line-0" onClick={onHide}>
                        <span className="material-symbols-outlined">close</span>
                    </button>
                </div>
                <div className="row px-3">                    
                    <div className="mb-3 col-12 col-xl-4 d-flex flex-column gap-2">
                        <h5 className="fw-bold">Beneficios de ser usuario profesional de ViTAG</h5>
                        <ul>
                            <li>Cantidad ilimitada de videos</li>
                            <li>Etiquetado de videos con IA</li>
                            <li>Acceso a contenidos de ViTAG</li>
                            <li>Acceso a canales de comunicación</li>
                        </ul>
                    </div>
                    { ! loading && products.map(prd =>
                        <div key={prd.id} className="mb-3 col-12 col-xl-4">
                            <div className="border-vitag d-flex flex-column gap-2 p-4 extra-rounded">
                                <h4 className="fw-bold text-center">{prd.name}</h4>
                                <div className="d-flex align-items-center justify-content-center gap-2">
                                    <span className="h1 m-0 fw-bold">
                                        € {(prd.priceAmount / 100).toFixed(2)}
                                    </span>
                                    { prd.priceInterval === 'month' && 
                                        <span className="text-muted" style={{lineHeight:'1', maxWidth:'64px'}}>Cobro mensual</span> }
                                    { prd.priceInterval === 'year' && 
                                        <span className="text-muted" style={{lineHeight:'1', maxWidth:'64px'}}>Cobro anual</span> }
                                </div>
                                <button onClick={() => tryToCreateCheckoutSession(prd.priceId)} className="btn btn-vitag w-100 text-center">
                                    Suscribirse
                                </button>
                                <span className="small text-muted">
                                    {prd.description}
                                </span>
                            </div>
                        </div>
                    ) }
                    {  loading && 
                        <>
                            <div className="mb-3 col-12 col-xl-4">
                                <SkeletonSubscriptionCard/>
                            </div>
                            <div className="mb-3 col-12 col-xl-4">
                                <SkeletonSubscriptionCard/>
                            </div>
                        </>
                     }
                    <div className="col-12 border-top pt-3 d-flex gap-3 justify-content-center align-items-center">
                        <img src="/assets/imgs/payments/powered-by-stripe.svg" alt="Powered by Stripe" width="150"/>
                        <span className="">
                            Todos los cobros de <b>ViTAG&copy;</b> se realizan a través de Stripe,
                            una plataforma de pagos en línea totalmente segura.
                        </span>
                    </div>
                </div>
            </div>
        </Modal>
    </>;

}

export { ModalExternalCheckout };