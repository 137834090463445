import React from "react";

const SkeletonSubscriptionCard = () => {
  const skeletonStyle = {
    background: "linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%)",
    backgroundSize: "200% 100%",
    animation: "skeleton-loading 1.5s infinite",
    borderRadius: "4px"
  };

  return (
    <div style={{ width: "100%", border: "2px solid #a22a26", borderRadius: "8px", padding: "20px", textAlign: "center" }}>
      {/* Título */}
      <div style={{ ...skeletonStyle, height: "24px", width: "80%", margin: "0 auto 10px" }}></div>
      <div style={{ ...skeletonStyle, height: "24px", width: "50%", margin: "0 auto 10px" }}></div>

      {/* Precio */}
      <div style={{ ...skeletonStyle, height: "48px", width: "60%", margin: "0 auto 10px" }}></div>

      {/* Botón */}
      <div style={{ ...skeletonStyle, height: "40px", width: "100%", marginBottom: "10px" }}></div>

      {/* Descripción */}
      <div style={{ ...skeletonStyle, height: "16px", width: "80%", marginBottom: "5px" }}></div>
      <div style={{ ...skeletonStyle, height: "16px", width: "60%", marginBottom: "5px" }}></div>
      <div style={{ ...skeletonStyle, height: "16px", width: "90%", marginBottom: "5px" }}></div>
      <div style={{ ...skeletonStyle, height: "16px", width: "70%", marginBottom: "5px" }}></div>

      {/* Animación en CSS */}
      <style>
        {`
          @keyframes skeleton-loading {
            0% { background-position: 200% 0; }
            100% { background-position: -200% 0; }
          }
        `}
      </style>
    </div>
  );
};

export default SkeletonSubscriptionCard;
