import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HomeBase } from "../home/HomeBase";
import { ProfileTabContexts } from "./ProfileTabContexts";
import { ProfileTabMainInfo } from "./ProfileTabMainInfo";
import { ProfileTabOtherOptions } from "./ProfileTabOtherOptions";
import packageJson from "../package.alias.json";
import { config } from "../config";
import { Documentation } from "../developer/Documentation";
import { PERMISSIONS, useCheckPermission } from "../utils/permissions/PermissionsUtils";
import { VitagProAd } from "../utils/ads/VitagProAd";

function ProfileHome() {

    const [option, setOption] = useState(0);
    const { t } = useTranslation();
    const accessDeveloperSection = useCheckPermission(PERMISSIONS.ACCESS_DEVELOPER_SECTION);

    useEffect(() => {
        document.title = config.TITLE_BASE + t('title_profile_home');
    }, []);

    return (
        <>
        <HomeBase
            currentTabName="profile"
            showSearchBar={false}
            showExtraButtons={false}>
            <div className="d-flex w-100 pt-3 pb-5" style={{zIndex:50}}>
                <div className="d-flex flex-column">
                    <div onClick={() => setOption(0)} className={ (option == 0 ? 'profile-tab-active bg-light ' : '')
                        + "profile-tab border-bottom w-100 py-3 px-3 px-md-5"}>
                        <span className="d-flex d-md-none cursor-pointer"
                            title={t('page_profile_section_personalinfo')}>
                            <span className="material-icons-round">person</span>
                        </span>
                        <span className="d-none d-md-flex cursor-pointer">
                            {t('page_profile_section_personalinfo')}
                        </span>
                    </div>
                    <div onClick={() => setOption(1)} className={ (option == 1 ? 'profile-tab-active bg-light ' : '')
                        + "profile-tab border-bottom w-100 py-3 px-3 px-md-5"}>
                        <span className="d-flex d-md-none cursor-pointer"
                            title={t('page_profile_section_contexts')}>
                            <span className="material-icons-round">home</span>
                        </span>
                        <span className="d-none d-md-flex cursor-pointer">
                            {t('page_profile_section_contexts')}
                        </span>
                    </div>
                    <div onClick={() => setOption(2)} className={ (option == 2 ? 'profile-tab-active bg-light ' : '')
                        + "profile-tab border-bottom w-100 py-3 px-3 px-md-5"}>
                        <span className="d-flex d-md-none cursor-pointer"
                            title={t('page_profile_section_config')}>
                            <span className="material-icons-round">settings</span>
                        </span>
                        <span className="d-none d-md-flex cursor-pointer">
                            {t('page_profile_section_config')}
                        </span>
                    </div>
                    { accessDeveloperSection &&
                    <div onClick={() => setOption(3)} className={ (option == 3 ? 'profile-tab-active bg-light ' : '')
                        + "profile-tab border-bottom w-100 py-3 px-3 px-md-5"}>
                        <span className="d-flex d-md-none cursor-pointer"
                            title={t('page_profile_section_developer')}>
                            <span className="material-icons-round">data_object</span>
                        </span>
                        <span className="d-none d-md-flex cursor-pointer">
                            {t('page_profile_section_developer')}
                        </span>
                    </div> }
                    { /* <div onClick={() => setOption(3)} className={ (option == 3 ? 'profile-tab-active bg-light ' : '')
                        + "profile-tab w-100 py-3 px-3 px-md-5"}>
                        <span className="d-flex d-md-none cursor-pointer"
                            title={t('Archivo')}>
                            <span className="material-icons-round">folder_delete</span>
                        </span>
                        <span className="d-none d-md-flex cursor-pointer">
                            {t('Archivo')}
                        </span>
                    </div> */ }
                </div>
                <div className="bg-light flex-grow-1 p-2 p-md-4">
                    { option == 0 && <ProfileTabMainInfo/>}
                    { option == 1 && <ProfileTabContexts/>}
                    { option == 2 && <ProfileTabOtherOptions/>}
                    { option == 3 && <Documentation/> }
                    { /*option == 3 && <RecyclerBin/>*/ }
                </div>
            </div>
        </HomeBase>
        <span className="position-fixed text-muted p-3" style={{bottom:0,left:0,zIndex:-1}}>
            ViTAG&copy; <b>v{packageJson.version}</b>
        </span>
        <VitagProAd></VitagProAd>
        </>
    );
}

export { ProfileHome };