import axios from "axios";
import { useEffect, useState } from "react";
import { config } from '../../config';
import { doLogout } from "../../services/AuthorizarionService";

const useAxiosLoader = () => {
    const [counter, setCounter] = useState(0);
    useEffect(() => {
      const inc = mod => setCounter(c => c + mod);
      const handleRequest = config => (inc(1), config);
      const handleResponse = response => (inc(-1), response);
      const handleError = error => (inc(-1), Promise.reject(error));
      const reqInterceptor = axios.interceptors.request.use(handleRequest, handleError);
      const resInterceptor = axios.interceptors.response.use(handleResponse, handleError);
      return () => {
        axios.interceptors.request.eject(reqInterceptor);
        axios.interceptors.response.eject(resInterceptor);
      };
    }, []);
    return counter > 0;
};

const GlobalLoader = () =>  {
    const loading = useAxiosLoader();
    return ( loading ?
    <div className="loading-indicator d-flex">
        <div className="my-auto spinner-border spinner-border-sm"></div>
        <span className="my-auto ml-2">Trabajando...</span>
    </div> : null )
}

const setupInterceptors = (history, location, openCheckoutModal) => {
    axios.defaults.baseURL = config.CORE_DOMAIN;
    axios.interceptors.request.use(config => {
        const token = localStorage.getItem('nibelungo');
        if (token) {
            config.headers = { Authorization: `Bearer ${token}` }
        }
        return config;
    });
    axios.interceptors.response.use(response => {
        return response;
    }, err => {
        const { status } = err?.response;
        if (status === 401) {
            doLogout();
            history.replace({pathname:'/login', state:{next:location.pathname}});
        }
        if (status === 457) {
            console.error('Error 457 from server', err);
            openCheckoutModal();
        }
        return Promise.reject(err);
    });
}

export { setupInterceptors, GlobalLoader };
